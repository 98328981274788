.image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .image-gallery > li {
    flex-basis: 350px; /* width: 350px; */
  }
  
  .image-gallery li img {
    /* object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-radius: 5px; */
    max-height:100%; max-width:100%;
  }

  .productInstrution{
    font-size: 25px;
  }