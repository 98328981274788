.itemContainer{
    background-image: url('../../../public/images/background.png');
    /* background-position: center; */
    /* background-size: cover; */
    background-repeat: repeat-y;
    color: rgb(0, 0, 0);
}
.titleDiv{
background: rgba(199, 199, 199, 0.5);
padding: 1em;
margin-top: 1em !important;
/* margin-bottom: 1em; */
text-align: center;
}

.storesButton{
    margin: 1px;
}