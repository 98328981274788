* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}




.services {
  background-image: url('../public/images/background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(0, 0, 0);
  font-size: 100px;
}

.products {
  background-image: url('../public/images/background.png');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: rgb(0, 0, 0);
  font-size: 100px;
}

.productInfo {
  background-image: url('../public/images/background.png');
  background-position: center;
  background-size: fill;
  background-repeat:repeat-y;
  color: rgb(0, 0, 0);
  /* font-size: 100px; */
}


.sign-up {
  /* display: flex; */
  min-height: 90vh;
  align-items: center;
  justify-content: center;

  background-image: url('../public/images/background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(0, 0, 0);
  margin-bottom: 10px;
}


.sign-up-card{
    padding: 4rem;
    background-color: rgba(221, 217, 217, 0.5);
    padding: 1em;
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    /* color: #fff; */
}
.sign-up-card-items{
  display: flex;
  flex: 2;
  

  /* flex: 0 0 100%; */
  margin: 0 1rem;
  border-radius: 10px;
  color: #000; 

}
.sign-up-item{
  flex: 1 1;
/* flex: auto; */
/* border: 1px solid black; */
list-style: none;
padding: 1em;
align-items: center;


 

}

.sign-up-item .btn-outer {
 display: flex;
 justify-content: center;
 width: 100%;
}